.md-btn {
  $mb_btn_anime: 0.5s;
  display: inline-flex;
  position: relative;
  z-index: 1;
  align-items: center; //中段に揃える
  justify-content: center; //水平上の中央に寄せる
  padding: 1em;
  border: 0;
  width: 100%;
  overflow: hidden;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  transition-duration: $mb_btn_anime;
  // テキストを左端に寄せる
  &.left-text {
    justify-content: flex-start;
    text-align: left;
  }

  // 角をつける
  &.sharp {
    border-radius: 0;
  }

  // 角を取る
  &.unsharp {
    border-radius: 5px;
  }

  // button系の子要素を装飾する
  &.mat {
    padding: 0;
    a,
    input[type="button"],
    input[type="submit"],
    button {
      display: inline-block;
      padding: 1em;
      border: 0;
      width: 100%;
      font-size: inherit;
      font-weight: inherit;
      line-height: 1;
      color: inherit;
      background: transparent;
      outline: none;
      transition: color 0s;
    }
  }

  // スライドアニメ演出用要素
  &::before {
    display: inline-block;
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
    background: none;
    transition-duration: $mb_btn_anime;
  }

  // デフォルト　左から右へスライド
  &:not(.reverse):before {
    left: -100%;
  }

  // 右から左へスライド
  &.reverse:before {
    right: -100%;
  }

  // スライドアニメを行わない
  &.stay:before {
    display: none;
  }

  // テキストの頭に添えるアイコン
  .head-icon {
    margin-right: 0.5em;
    margin-left: -1em;
  }

  // ボタンの左右どちらかに置くアイコン
  .left-icon,
  .right-icon {
    position: absolute;
    top: 50%;
    z-index: 10;
    pointer-events: none;
    transition: color 0s;
    transform: translate(0, -50%);
  }

  // 右アイコンの詳細位置
  .right-icon {
    right: 0.8em;
    transition: right 0.5s;
  }

  // 左アイコンの詳細位置
  .left-icon {
    left: 0.8em;
    transition: left 0.5s;
  }

  &:hover,
  a:hover & {
    text-decoration: none;
    &:not(.reverse):before {
      left: 0;
    }
    &.reverse:before {
      right: 0;
    }
    .right-icon:not(.stay) {
      right: 0.5em;
    }
    .left-icon:not(.stay) {
      left: 0.5em;
    }
  }
  // 幅を中のテキストに依存させる
  &.wauto {
    width: auto;
  }
  /*
        ######   #######  ##        #######  ########
       ##       ##     ## ##       ##     ## ##     ##
       ##       ##     ## ##       ##     ## ########
       ##       ##     ## ##       ##     ## ##   ##
        ######   #######  ########  #######  ##     ##
*/
  &.white {
    $btncolor: #fff;
    $textcolor: #000;
    border: solid 1px $btncolor;
    color: $textcolor;
    background-color: $btncolor;
    &:before,
    &:hover,
    a:hover & {
      color: $btncolor;
      background-color: $textcolor;
    }
  }
  &.black {
    $btncolor: #000;
    $textcolor: #fff;
    border: solid 1px $btncolor;
    color: $textcolor;
    background-color: $btncolor;

    &:before,
    &:hover,
    a:hover & {
      color: $btncolor;
      background-color: $textcolor;
    }
  }

  &.w-line {
    $btncolor: #fff;
    $textcolor: #fff;
    border: solid 1px $btncolor;
    color: $textcolor;

    &:before,
    &:hover,
    a:hover & {
      color: #000;
      background-color: $btncolor;
    }
  }

  &.b-line {
    $btncolor: #000;
    $textcolor: #000;
    border: solid 1px $btncolor;
    color: $textcolor;

    &:before,
    &:hover,
    a:hover & {
      color: #fff;
      background-color: $btncolor;
    }
  }

  &.plain {
    // 背景なし
    color: $fontColor;
    &:before,
    &:hover,
    a:hover & {
      color: $fontColor;
      background: none;
    }
  }
  // ↓SP以外の幅
  @media #{$media-notsp} {
    &.w300 {
      width: 300px;
    }
  }
  // ↑SP以外の幅
}

/*
#styleguide
ボタン .md-btn

指定したタグをボタン風にする。
マウスホバーもしくは「active」クラスでアニメーションする。aタグの小要素に使っている場合は、親aタグの状態にも反応する。
「mat」を付けることで、直下のボタン要素を装飾することもできる。
動く系のアニメは「stay」で止められる。
```
<a class="md-btn w300 black" href="#">
  次のページ
  <i class="icon fas fa-fw fa-chevron-right right-icon"></i>
</a>
<span class="md-btn b-line w300 mat stay">
  <i class="icon fas fa-fw fa-check left-icon stay"></i>
  <input type="submit" value="入力内容を確認する">
</span>

<a class="md-btn w300 black reverse" href="#">
  <i class="icon fas fa-fw fa-chevron-left left-icon"></i>
  戻る
</a>
<a class="md-btn w300 b-line left-text" href="#">
  テキストテキスト<br>テキスト
</a>
```
*/
