// ヘッダーの高さ
$headerPC: 10rem;
$headerSP: 6rem;
.l-navi-sp {
  @media #{$media-sp} {
    display: none;
  }
}

.header-tel {
  .pc-flex {
    display: flex;
    a {
      font-size: 1.2rem;
      color: #333;
      text-decoration: none;
      .ta-center {
        font-size: 12px;
      }
      .imgtell {
        position: relative;
        top: -1px;
        right: -0.3rem;
      }
      .imgtell2 {
        position: relative;
        top: -1px;
        padding: 0 0 0.2rem;
      }
      .btxt {
        position: relative;
        top: -1px;
        padding: 0 0 0 0;
        font-size: 12px;
      }
    }
    .fu {
      position: relative;
      top: 1px;
    }
  }
  &.leftvv {
    position: absolute;
    left: 0px;
    padding: 10px 0 17px 0;
    width: 370px;
    text-align: right;
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  white-space: nowrap;
  background: #fff;
  box-shadow: 0 0 10px rgba(#000, 0.1);
  &__frame {
    padding: 0 0 0 2rem;
    background: #000;
  }
  &__base {
  }
  &__logo {
  }
  &__logo-img {
    display: block;
    max-width: 15rem;
  }
  &__logo-text {
  }
  &__nav {
    margin-right: 1rem;
  }
  /*
               ######  ########
              ##       ##     ##
      #######  ######  ########
                    ## ##
               ######  ##
  */
  // ↓sp相当幅
  @media #{$media-sp} {
    max-height: 100%;
    overflow: auto;

    &__frame {
      padding: 0;
    }
    &__base {
    }
    &__logo {
    }
    &__logo-img {
      max-width: 12rem;
    }
    &__logo-text {
    }
    &__nav {
    }
  }
  // ↑SP相当幅
}

/*
 ########  ##     ## ##       ##       ########   #######  ##      ## ##    ##
 ##     ## ##     ## ##       ##       ##     ## ##     ## ##  ##  ## ####  ##
 ########  ##     ## ##       ##       ##     ## ##     ## ##  ##  ## ## ## ##
 ##        ##     ## ##       ##       ##     ## ##     ## ##  ##  ## ##  ####
 ##         #######  ######## ######## ########   #######   ###  ###  ##    ##
*/
// プルダウンメニュー
.pulldown {
  &__head {
    padding: 1rem 2.5rem;
    height: $headerPC;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    cursor: pointer;
    transition-duration: 0.5s;
  }
  &__body {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    padding: 3.5rem 0;
    width: 100%;
    background: #f00;
    opacity: 0;
    transition-duration: 0.5s;
    transform: rotateX(90deg);
    transform-origin: 0 0;
  }

  &:hover {
    .pulldown {
      &__head {
        &::before {
          transform: none;
        }
      }
      &__body {
        opacity: 1;
        transform: rotateX(0);
      }
    }
  }
}

/*
  ######  ########          ##    ##    ###    ##     ##
 ##       ##     ##         ####  ##  ##   ##  ##     ##
  ######  ########  ####### ## ## ## ##     ## ##     ##
       ## ##                ##  #### #########  ##   ##
  ######  ##                ##    ## ##     ##    ###
*/
.spNav {
  position: fixed;
  top: $headerSP;
  left: 0;
  z-index: 1000;
  width: 100%;
  max-height: calc(100% - #{$headerSP});
  overflow-y: auto;

  background: #00f;
  transition-duration: 0.5s;
  transform-origin: 0 0;
  // ↓SP以外の幅
  @media #{$media-notsp} {
    top: $headerPC;
  }
  // ↑SP以外の幅
  &:not(.active) {
    visibility: hidden;
    transform: rotateX(90deg);
  }
  &__list {
  }
  &__item {
    padding: 1.5rem;
    border-bottom: 1px solid #f00;
    font-weight: 500;
    color: #fff;
    background: #00f;
    &--sub {
      background: #00ffff;
    }
  }

  .md-acc {
    .acc-btn {
      position: relative;
      .icon {
        position: absolute;
        top: 50%;
        right: 2rem;
        z-index: 2;
        font-size: 1rem;
        transition-duration: 0.3s;
        transform: translate(0%, -50%);
      }
    }
    .acc-body {
      * {
      }
    }
    .acc-check:not(:checked) ~ {
      .acc-btn {
      }
      .acc-body {
      }
    }
    .acc-check:checked ~ {
      .acc-btn {
        .icon {
          transform: translate(0%, -50%) rotate(180deg);
        }
      }
      .acc-body {
        transition-duration: 1s;
        transform: none;
      }
    }
  }
}

/*
  ######  ########          ########  ######## ##    ##
 ##       ##     ##         ##     ##    ##    ####  ##
  ######  ########  ####### ########     ##    ## ## ##
       ## ##                ##     ##    ##    ##  ####
  ######  ##                ########     ##    ##    ##
*/
.spMenuBtn {
  $btnSize: $headerSP; //ボタン一辺の長さ
  $bar: 3px; //バーの太さ
  $posX: 1.5rem; //バーの左右の余白
  $posY: 1.75rem; //1,3本目のバーの上下の余白
  $barRadius: 0; //バーの角丸
  $barOffBG: #fff; //バーの背景OFF時
  $barOnBG: #fff; //バーの背景ON時
  $time: 0.5s; //アニメ時間　0.1s以上
  display: inline-block;
  position: relative;
  width: $btnSize;
  height: $btnSize;
  vertical-align: middle;
  cursor: pointer;
  background: $siteColor;
  &__box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  &__bar {
    display: block;
    position: absolute;
    left: $posX;
    z-index: 2;
    border-radius: $barRadius;
    width: calc(100% - #{$posX} * 2);
    height: $bar;
    background: $barOffBG;
    transition-duration: $time;
    transform-origin: 50% 50%;
    &:nth-of-type(1) {
      top: $posY;
    }
    &:nth-of-type(2) {
      top: 50%;
      margin-top: $bar / -2;
    }
    &:nth-of-type(3) {
      bottom: $posY;
    }
  }
  &.active {
    .spMenuBtn {
      &__bar {
        background: $barOnBG;
        &:nth-of-type(1) {
          top: 50%;
          margin-top: $bar / -2;
          transition: top $time, margin $time, transform $time ease $time;
          transform: rotate(-45deg);
        }
        &:nth-of-type(2) {
          opacity: 0;
          transition-delay: $time - 0.1s;
          transform: rotateY(90deg);
        }
        &:nth-of-type(3) {
          bottom: 50%;
          margin-bottom: $bar / -2;
          transition: bottom $time, margin $time, transform $time ease $time;
          transform: rotate(45deg);
        }
      }
    }
  }
}
