.clm2-box {
  .photo-left {
    &.v-middle {
      width: 50%;
      img {
        display: inline-block;
        width: 300px;
      }
      @media #{$media-sp} {
        width: 40%;
      }
    }
  }
  .txt {
    width: 50%;
    @media #{$media-sp} {
      width: 60%;
    }
  }
}

.wktn {
  display: flex;
  position: relative;
  padding: 10px 0;

  border: solid 1px #c5d5e4;
  text-decoration: none;
  background: #fff;
  &:active {
    text-decoration: none;
  }
  &::after {
    position: absolute;
    top: 40%;
    right: 10px;
    content: url("../assets/img/layout/header/wktn2.png");
    @media #{$media-sp} {
      top: 35%;
    }
  }
  &::before {
    position: absolute;
    top: 0;
    right: 25px;
    width: 1px;
    height: 100%;
    content: "";
    background: #e5eef7;
  }
  .img {
    padding: 0 1rem 0 1rem;
    @media #{$media-sp} {
      position: relative;
      top: 5px;
      width: 25%;
      img {
        width: 100px;
      }
    }
  }
  .text {
    align-items: center;
    margin: auto 0;
    font-size: 14px;
    line-height: 1.5;
    color: #333;
    text-decoration: none !important;
    @media #{$media-sp} {
      width: 70%;
      font-size: 22px;
    }
    span {
      font-size: 13px;
      color: #386a9c;
      @media #{$media-sp} {
        font-size: 18px;
      }
    }
  }
}
